const Works = [
  {img : "Dashboard.png", title : "Dashboard" , visit: "https://shtya.github.io/Dashboard/" , github :"https://github.com/Shtya/Dashboard" },
  {img : "Special.png", title : "Design Special" , visit: "https://shtya.github.io/Special_Design/" , github :"https://github.com/Shtya/Special_Design" },
  {img : "Gym.png", title : "Gym" , visit: "https://gym-etg.pages.dev/" , github :"https://github.com/Shtya/GYM" },
  {img : "Dev.png", title : "Dev" , visit: "https://shtya.github.io/Dev/" , github :"https://github.com/Shtya/Dev" },
  {img : "Kasper.png", title : "Kasper" , visit: "https://shtya.github.io/Kasper/" , github :"https://github.com/Shtya/Kasper" },
  {img : "Leon.png", title : "Leon" , visit: "https://shtya.github.io/Project-Leon/" , github :"https://github.com/Shtya/Project-Leon" },
]



export default Works
